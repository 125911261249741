import React from "react"

import style from "./secondarybutton.module.css"

import { Link } from "gatsby"

const SecondaryButton = ({ buttonText, onClick }) => {
  return (
    <button className={style.secondaryButton} onClick={onClick}>
      {buttonText}
    </button>
  )
}
export default SecondaryButton
