import { useState, useEffect , useRef} from 'react';

const useForm = (callback, validate) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }else{
      setIsSubmitting(false)
    }
  }, [errors]);

  // useEffect(() =>{
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   setErrors(validate(values))
  // },[values])

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));   
    //setErrors(validate(values,event.target.name))
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
};

export default useForm;