import React from "react"

import style from "./thirdsection.module.css"

import { Link } from "gatsby"

import searching from "../vectors/searching.svg"
import flask from "../vectors/flask.svg"
import codebranch from "../vectors/codebranch.svg"
import user from "../vectors/user.svg"
import chart from "../vectors/chart.svg"

const ThirdSection = ({
  card1Pic,
  card1Text,
  card2Pic,
  card2Text,
  card3Pic,
  card3Text,
  card4Pic,
  card4Text,
  card5Pic,
  card5Text,
}) => {
  return (
    <div className={style.thirdSection}>
      <div className={style.title}>
        <h2>How we can help you do more with less</h2>
      </div>
      <div className={style.processGrid}>
        <div className={style.processPicture} ref={card1Pic}>
          <img src={searching} />
        </div>
        <div className={style.processCardBlank}></div>
        <div className={style.processCard} ref={card1Text}>
          <h3>1. Investigation</h3>
          <p>
            The first step is to discover a companies needs. This starts by
            looking at the current process and identifying which parts of this
            could be refined.
          </p>
        </div>
        <div className={style.processPicture} ref={card2Pic}>
          <img src={flask} />
        </div>
        <div className={style.processPicture} ref={card3Pic}>
          <img src={codebranch} />
        </div>
        <div className={style.processCard} ref={card2Text}>
          <h3>2. Solution Design</h3>
          <p>
            Now to design a process which will solve your requirements. Keeping
            you up to speed with this process is key to ensure you get the
            product need.
          </p>
        </div>
        <div className={style.processCard} ref={card3Text}>
          <h3>3. Implementation</h3>
          <p>
            Once the design is finalised it's time to build the solution. At
            this stage testing is crucial to make sure the application is
            trouble free.
          </p>
        </div>
        <div className={style.processPicture} ref={card4Pic}>
          <img src={user} />
        </div>
        <div className={style.processPicture} ref={card5Pic}>
          <img src={chart} />
        </div>
        <div className={style.processCard} ref={card4Text}>
          <h3>4. Training</h3>
          <p>
            Now the solution is up and running. Your users need to understand
            how it works and how it saves them time. Training in the different
            forms plays a part in this.
          </p>
        </div>
        <div className={style.processCard} ref={card5Text}>
          <h3>5. Monitoring</h3>
          <p>
            Lastly monitoring, bug reports and user feedback is important now.
            To ensure the solutions runs efficiently and continues to save you
            time and money.
          </p>
        </div>
        <div className={style.processCardBlank}></div>
      </div>
    </div>
  )
}
export default ThirdSection
