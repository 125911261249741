import React, { forwardRef } from "react"

import style from "./firstsection.module.css"

import { Link } from "gatsby"
import PrimaryButton from "../components/primarybutton"

const FirstSection = ({
  showModel,
  isModalOpen,
  mountainsStart,
  headline1Ref,
  headline2Ref,
  headline3Ref,
  headline4Ref,
}) => {
  return (
    <div className={style.firstSection}>
      <div className={style.headline} ref={mountainsStart}>
        <h1 ref={headline1Ref}>Wasted Time Killers</h1>
        <h5 ref={headline2Ref}>
          We help businesses eliminate waste from their <br />
          workflows by designing bespoke automation software.
        </h5>
        <h6 ref={headline3Ref}>Ready to see how much time you could save?</h6>
        <div className={style.headlineButton} ref={headline4Ref}>
          <PrimaryButton
            buttonText={"BOOK A FREE CONSULTATION"}
            onClick={() => showModel("Consultation")}
          />
        </div>
      </div>
    </div>
  )
}
export default FirstSection
