
export default function validate(values) {
    let errors = {};
    if (!values.modalTelephone) {
      errors.modalTelephone = 'Telephone is required';
    }  
    if (!values.modalName) {
      errors.modalName = 'Name is required';
    } 
    if (!values.modalCompany) {
      errors.modalCompany = 'Company is required';
    } 
    return errors;
  };