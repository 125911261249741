import React from "react"

import style from "./fourthsection.module.css"

import { Link } from "gatsby"

import PrimaryButton from "../components/primarybutton"
import SecondaryButton from "../components/secondarybutton"

import automation from "../vectors/automation.svg"
import apps from "../vectors/apps.svg"
import websites from "../vectors/websites.svg"

const FourthSection = ({ showModel, mountainsEnd }) => {
  return (
    <div className={style.fourthSection} ref={mountainsEnd}>
      <div className={style.sectionTitle}>
        <h1>Our Services</h1>
      </div>
      <div className={style.serviceBlock}>
        <div className={style.serviceCard}>
          <div className={style.serviceTitle}>
            <img src={automation} />
            <h3>Automation Software Development</h3>
          </div>
          <div className={style.serviceDescription}>
            <p>
              Your business could benefit from bespoke automation software which
              could save your company time and money
            </p>
          </div>
          <div className={style.serviceContact}>
            <PrimaryButton
              buttonText={"START A CONVERSATION"}
              onClick={() =>
                window.location.assign("mailto:info@cognitivesoftware.co.uk")
              }
            />
            <SecondaryButton
              buttonText={"STATS ROBOT SOLUTION"}
              onClick={() =>
                (window.location.href =
                  "https://statsrobot.cognitivesoftware.co.uk/")
              }
            />
          </div>
        </div>
      </div>
      <div className={style.serviceBlock}>
        <div className={style.serviceCard}>
          <div className={style.serviceTitle}>
            <img src={apps} />
            <h3>Bespoke Web and Mobile Applications</h3>
          </div>
          <div className={style.serviceDescription}>
            <p>
              looking for an app or mobile application. We are partnered with
              loopspeed&#8482;. Head over to their website to see what can be
              done for you.
            </p>
          </div>
          <div className={style.serviceContact}>
            <SecondaryButton
              buttonText={"FIND OUT MORE"}
              onClick={() =>
                (window.location.href = "https://loopspeed.co.uk/")
              }
            />
          </div>
        </div>
      </div>
      <div className={style.serviceBlock}>
        <div className={style.serviceCard}>
          <div className={style.serviceTitle}>
            <img src={websites} />
            <h3>Optimised Website Development</h3>
          </div>
          <div className={style.serviceDescription}>
            <p>
              Looking for an optimised web platform for your business. We are
              partnered with loopspeed&#8482;. Head over to their website to see
              what can be done for you.
            </p>
          </div>
          <div className={style.serviceContact}>
            <SecondaryButton
              buttonText={"FIND OUT MORE"}
              onClick={() =>
                (window.location.href = "https://loopspeed.co.uk/")
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default FourthSection
