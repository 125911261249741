import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Menu from "../components/menu"
import Notice from "../components/notice"

import mountain from "../vectors/mountain.svg"

import style from "./index.module.css"
import FirstSection from "../components/firstsection"
import SecondSection from "../components/secondsection"
import ThirdSection from "../components/thirdsection"
import FourthSection from "../components/fourthsection"
import Footer from "../components/footer"
import Modal from "../components/modal"

import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { number } from "prop-types"

gsap.registerPlugin(ScrollTrigger)

const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setModalText] = useState("")

  let mountains = null
  let mountainsStart = null
  let mountainsEnd = null
  let menuRef = null
  let emailRef = null
  let logoRef = null
  let navRef = null

  let headline1Ref = null
  let headline2Ref = null
  let headline3Ref = null
  let headline4Ref = null
  let secondsectionRef = null

  let minutesRef = null

  let card1Pic = null
  let card1Text = null
  let card2Pic = null
  let card2Text = null
  let card3Pic = null
  let card3Text = null
  let card4Pic = null
  let card4Text = null
  let card5Pic = null
  let card5Text = null

  let wrapperBlur = null

  useEffect(() => {
    gsap.to(mountains, {
      scrollTrigger: {
        trigger: mountainsStart,
        start: "70px 60px;",
        scrub: true,
        endTrigger: mountainsEnd,
        end: "top top",
      },
      x: -150,
    })
  }, [])

  useEffect(() => {
    if (window.innerWidth < 767) {
    } else {
      gsap.to(menuRef, {
        scrollTrigger: {
          trigger: mountainsStart,
          start: "70px 60px",
          toggleActions: "play none reverse none",
          ease: "power2.out",
          end: "+=1px",
        },
        height: 50,
        backgroundColor: "rgba(255, 255, 255, 1)",
        duration: 0.06,
      })
      gsap.to(menuRef, {
        scrollTrigger: {
          trigger: mountainsStart,
          start: "70px 60px",
          toggleActions: "play none reverse none",
          ease: "power2.out",
          end: "+=1px",
        },
        borderBottom: "2px solid #e8e8e8",
        duration: 0.002,
      })
      gsap.to(logoRef, {
        scrollTrigger: {
          trigger: mountainsStart,
          start: "70px 60px",
          toggleActions: "play none reverse none",
          end: "+=1px",
        },
        x: -50,
        width: "200px",
        duration: 0.4,
      })
      gsap.to(navRef, {
        scrollTrigger: {
          trigger: mountainsStart,
          start: "70px 60px",
          toggleActions: "play none reverse none",
          end: "+=1px",
        },
        x: 50,
        duration: 0.4,
      })
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth < 767) {
    } else {
      gsap.to(headline1Ref, {
        scrollTrigger: {
          trigger: headline1Ref,
          start: "0px 200px;",
          end: "+=400px",
          scrub: true,
          toggleActions: "play none reverse none",
        },
        y: 200,
      })
      gsap.to(headline2Ref, {
        scrollTrigger: {
          trigger: headline1Ref,
          start: "0px 200px;",
          end: "+=500px",
          scrub: true,
          toggleActions: "play none reverse none",
        },
        y: 200,
      })
      gsap.to(headline3Ref, {
        scrollTrigger: {
          trigger: headline1Ref,
          start: "-20px 200px;",
          end: "+=600px",
          scrub: true,
          toggleActions: "play none reverse none",
        },
        y: 200,
      })
      gsap.to(headline4Ref, {
        scrollTrigger: {
          trigger: headline1Ref,
          start: "-20px 200px;",
          end: "+=600px",
          scrub: true,
          toggleActions: "play none reverse none",
        },
        y: 200,
      })
      gsap.to(secondsectionRef, {
        scrollTrigger: {
          trigger: headline1Ref,
          start: "-20px 200px;",
          end: "+=600px",
          scrub: true,
          toggleActions: "play none reverse none",
        },
        y: 100,
      })
    }
  }, [])

  const [minutes, setMinutes] = useState(0)

  useEffect(() => {
    var counter = { var: 0 }
    gsap.to(counter, {
      scrollTrigger: {
        trigger: minutesRef,
        start: "bottom bottom",
        end: "+=100",
        toggleActions: "play play reset play",
      },
      var: "412",
      duration: 3,
      ease: "expo.out",
      onUpdate: () => setMinutes(Math.ceil(counter.var)),
    })
  }, [])

  useEffect(() => {
    gsap.to(card1Pic, {
      scrollTrigger: {
        trigger: card1Pic,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card1Text, {
      scrollTrigger: {
        trigger: card1Text,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card2Pic, {
      scrollTrigger: {
        trigger: card2Pic,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card2Text, {
      scrollTrigger: {
        trigger: card2Text,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card3Pic, {
      scrollTrigger: {
        trigger: card3Pic,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card3Text, {
      scrollTrigger: {
        trigger: card3Text,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card4Pic, {
      scrollTrigger: {
        trigger: card4Pic,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card4Text, {
      scrollTrigger: {
        trigger: card4Text,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card5Pic, {
      scrollTrigger: {
        trigger: card5Pic,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
    gsap.to(card5Text, {
      scrollTrigger: {
        trigger: card5Text,
        start: "-300px center",
        scrub: true,
        end: "+=400px",
        duration: 2,
      },
      opacity: 1.0,
    })
  })

  const showModal = whichModal => {
    if (!isModalOpen) {
      gsap.to(wrapperBlur, { filter: "blur(10px", duration: 0.6 })
    } else {
      gsap.to(wrapperBlur, { filter: "blur(0px", duration: 0.6 })
    }

    isModalOpen
      ? (document.getElementsByTagName("body")[0].style.overflowY = "scroll")
      : (document.getElementsByTagName("body")[0].style.overflowY = "hidden")
    setIsModalOpen(!isModalOpen)
    setModalText(whichModal)
  }
  return (
    <>
      <SEO title="Automation" />
      {isModalOpen && (
        <div className={style.modalOverlay} onClick={() => showModal()}>
          <Modal
            modalText={modalText}
            showModal={showModal}
            isModalOpen={isModalOpen}
          />
        </div>
      )}
      <Menu
        showModel={showModal}
        isModalOpen={isModalOpen}
        menuRef={e => (menuRef = e)}
        emailRef={e => (emailRef = e)}
        logoRef={e => (logoRef = e)}
        navRef={e => (navRef = e)}
      />
      <div className={style.backgroundGradient}>
        <div className={isModalOpen ? style.mountainModal : style.mountain}>
          <img src={mountain} ref={e => (mountains = e)} />
        </div>
      </div>
      <div
        className={isModalOpen ? style.wrapperModalOpen : style.wrapper}
        ref={e => (wrapperBlur = e)}
      >
        {/* <Notice /> */}

        <FirstSection
          showModel={showModal}
          isModalOpen={isModalOpen}
          mountainsStart={e => (mountainsStart = e)}
          headline1Ref={e => (headline1Ref = e)}
          headline2Ref={e => (headline2Ref = e)}
          headline3Ref={e => (headline3Ref = e)}
          headline4Ref={e => (headline4Ref = e)}
        />
        <SecondSection
          secondsectionRef={e => (secondsectionRef = e)}
          minutesRef={e => (minutesRef = e)}
          minutes={minutes}
        />
        <ThirdSection
          card1Pic={e => (card1Pic = e)}
          card1Text={e => (card1Text = e)}
          card2Pic={e => (card2Pic = e)}
          card2Text={e => (card2Text = e)}
          card3Pic={e => (card3Pic = e)}
          card3Text={e => (card3Text = e)}
          card4Pic={e => (card4Pic = e)}
          card4Text={e => (card4Text = e)}
          card5Pic={e => (card5Pic = e)}
          card5Text={e => (card5Text = e)}
        />
        <FourthSection
          showModel={showModal}
          mountainsEnd={e => (mountainsEnd = e)}
        />
        <Footer />
      </div>
    </>
  )
}

export default IndexPage
