import React from "react"

import style from "./footer.module.css"

import { Link } from "gatsby"

import logo from "../images/cognitive.png"

const Footer = () => {
  var d = new Date()
  var n = d.getFullYear()
  return (
    <>
      <footer>
        <div className={style.logo}>
          <img src={logo} width={150} />
        </div>
        <div className={style.aboutUs}>
          <p>
            Cognitive Software Limited is a tech based automation service
            provider.
            <br /> Creators of the StatsRobot.
          </p>
        </div>
      </footer>
      <div className={style.legal}>
        <p>{n}© cognitive software limited</p>
      </div>
    </>
  )
}
export default Footer
